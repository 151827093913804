module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Energieberatungszentrum Kassel","short_name":"EBZ Kassel","start_url":"/","background_color":"#a0d3c7","theme_color":"#a0d3c7","icon":"src/images/favicon.png","icon_options":{"purpose":"any maskable"},"legacy":false,"display":"standalone","theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d81d44fe520dd31d8d0a40e2c2d1557a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"6","matomoUrl":"https://www.sw-kassel.de/matomo","siteUrl":"https://www.ebz-kassel.de"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
